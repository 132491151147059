
export default {
    // props: ['items'],
    props: {
        items: {
            type: Array,
            default: null,
        },
        titles: Array,
        styleClass: null,
    },

    data() {
        return {
            customClass: '',
        }
    },

    mounted() {
        // console.log('this.styleClass', typeof this.styleClass, this.styleClass)
        this.customClass =
            typeof this.styleClass === 'object'
                ? this.styleClass.join(' ')
                : this.styleClass
    },

    methods: {
        onClickItem(id) {
            this.$emit('clickItem', id)
        },
        onClickTheme(themeCode) {
            this.$router.push(`/Theme/ThemeDB/${themeCode}`)
        },
    },
}
