
export default {
    props: ['items', 'styleClass'],
    /* props: {
    items: {
      type: Array,
      default: null,
    },
  }, */

    methods: {
        onClickItem(id) {
            this.$emit('clickItem', id)
        },
    },
}
