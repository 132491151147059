
import { mapState, mapActions } from 'vuex'
import dayjs from 'dayjs'
import api from '~/api/index'
import List from '~/components/Common/UI/List_3Col'

export default {
    name: 'NewsFlash',

    components: { List },

    props: [],

    data() {
        return {
            newsList: {
                items: [],
                nextKey: '',
            },
            newsType: 'MARKET_NEWSFLASH',
        }
    },

    computed: {
        ...mapState ({
            MEMBER_INFO : state => state.member.memberInfo,
        }),

        board() {
            return this.$store.state.board.newsList[this.newsType]
        },
    },

    created() {
        this.getList()
    },

    methods: {
        ...mapActions('modal', ['SHOW_ALERT']),

        async getList() {
            // this.newsList = (await api.getMainTickerList()).data
            await api.getMainTickerList().then((res)=>{
                if (res && res.success){
                    this.newsList = res.data
                    // console.log('this.newsList', this.newsList)
                } else {
                    alert('main_page newsflash err')
                }
            }).catch((err)=>{
                console.warn("main_page newsflash -> ", err)
                alert(err)
            })

        },

        onClickItem(item) {
            /* 220610 meej 메인페이지에서 속보제목 클릭할경우 */
            // 유료회원 아닐 경우
            if(this.MEMBER_INFO === null 
                || (this.MEMBER_INFO !== null && this.MEMBER_INFO.type !== 'REGULAR' 
                && this.MEMBER_INFO.type !== 'CORPORATE')){
                const today = dayjs(new Date()).format('YYYYMMDD');
                if(today === item.sendDate){
                    this.SHOW_ALERT({ content: '유료회원님들을 위한 자료입니다.' })
                    return 
                }  
            }

            const id = item.id
            const newsType = item.newsType1
            // 유료회원 구분을 위해 sendDate 추가
            const sendDate = item.sendDate

            this.$router.push({
                path: '/MarketNewsFlash',
                query: { id, newsType, sendDate },
            })
        },
    },
}
