
import { mapState, mapActions } from 'vuex'
export default {
  name: "Chart",
  components: {
    [process.browser && 'VueApexCharts']: () => import('vue-apexcharts'),
  },
  
  data() {
      return {
          series1: [{ data: [] }],
          series2: [{ data: [] }],
          chartOptions: {
              chart: {
                  type: "candlestick", 
                  height: 300,
                  toolbar: {
                      show: false,
                  },
              },
              xaxis: {
                  type: 'category',
                  tickAmount: 3,
                  tooltip: {
                      enabled: false
                  },
                  labels: {
                      rotate: 0
                  }
              },
              yaxis: {
                  tooltip: {
                      enabled: true
                  }
              },
              plotOptions: {
                  candlestick: {
                      colors: {
                          upward: '#EF403C',
                          downward: '#000077',
                      },
                      wick: {
                          useFillColor: true,
                      },
                  },
              },
          },
      }
  },
  computed: {
      ...mapState('theme', ['items1', 'items2']),
      defaultCharts1() {
          return this.items1.map(item => ({
              x: item.openDate.substr(2, 8),
              y: [item.openPrice, item.highPrice, item.lowPrice, item.closePrice]
          })).reverse();
      },
      defaultCharts2() {
          return this.items2.map(item => ({
              x: item.openDate.substr(2, 8),
              y: [item.openPrice, item.highPrice, item.lowPrice, item.closePrice]
          })).reverse();
      }
  },
  watch: {
      items1(newVal) {
          this.series1[0].data = this.defaultCharts1;
          this.$nextTick(() => {
              if (this.$refs.chart1) {
                  this.$refs.chart1.updateSeries(this.series1, true);
              }
          });
      },
      items2(newVal) {
          this.series2[0].data = this.defaultCharts2;
          this.$nextTick(() => {
              if (this.$refs.chart2) {
                  this.$refs.chart2.updateSeries(this.series2, true);
              }
          });
      }
  },
  created() {
      this.getData1();
      this.getData2();
  },
  methods: {
      ...mapActions('theme', ['GET_CHART1', 'GET_CHART2']),
      
      getData1() {
          const param = 
          { 
            'indexId': 'YYYYYY' 
          };
          this.GET_CHART1(param).then(() => {
              console.log("this.items1: ", this.items1);
              this.series1[0].data = this.defaultCharts1;
          });
      },
      getData2() {
          const param = 
          { 
            'indexId': 'KKKKKK' 
          };
          this.GET_CHART2(param).then(() => {
              console.log("this.items2: ", this.items2);
              this.series2[0].data = this.defaultCharts2;
          });
      },
  }
}
