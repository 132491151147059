
import { mapMutations, mapActions, mapGetters } from 'vuex'

export default {
    name: 'StockIndex',
    computed: {
        ...mapGetters('main', ['kospi', 'kosdaq']),
    },
    created() {
        this.requestIndex()
    },
    methods: {
        ...mapActions('main', ['GET_MARKET_INDEX']),
        ...mapMutations('main', ['SET_MARKET_INDEX']),

        requestIndex() {
            const params = {
                body: '',
            }
            this.GET_MARKET_INDEX(params)
        },

        onClickKospiImage() {
            const uri =
                'http://www.paxnet.co.kr/stock/sise/KOSPI?wlog_rpax=KOSPI'
            window.open(uri, '_blank')
        },
        onClickKosdaqImage() {
            const uri =
                'http://www.paxnet.co.kr/stock/sise/KOSDAQ?wlog_rpax=KOSDAQ'
            window.open(uri, '_blank')
        },
    },
}
