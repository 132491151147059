
import { mapMutations, mapState, mapActions } from 'vuex'

import CONSTANTS from '~/constants'
import api from '~/api/' // server
import storage, { keys } from '~/services/storage'
import FindinfoSns from '~/components/Common/Modal/Findinfo_sns'

export default {
    name: 'Login',

    components: {
        FindinfoSns,
    },
    props: [],

    data() {
        return {
            id: '',
            password: '',
            checkedIdSave: false,
            // loginInfo: null,
            // SNS
            isIdDupCheck: null,
            params: {}
        }
    },

    computed: {
        ...mapState('member', ['memberInfo', 'memberPhoneNo']),
    },
    watch: {
        memberPhoneNo(newVal) {
            console.log(" 멤버 폰 넘버 : ", newVal)
            if (newVal && newVal.trim() !== '') {
                this.getNext()
            }
        }
    },

    created() {
        console.log('memberInfo===',this.memberInfo)
        /**
         * localStorage 접근이 안되서 null을 리턴함
         * 그래서 beforeMount 에서 처리함
         */
        // const loginUserInfo = storage.get(keys.loginInfo) // 저잗된 값이 없으면 null
        // console.log('created loginUserInfo', loginUserInfo)
    },

    beforeMount() {
        // id 저장 여부 처리
        const savedId = storage.get(keys.id)
        if (savedId) {
            this.id = savedId
            this.checkedIdSave = true
        }

        /**
         * keys.loginInfo 정보가 저장되어 있으면 로그인 상태임
         */
        const loginInfo = storage.get(keys.loginInfo) // 저잗된 값이 없으면 null
        console.log('beforeMount loginInfo', loginInfo)

        // 회원정보 store에 갱신
        if (loginInfo) {
            this.getMemberInfo(loginInfo.id)
        }
    },
    mounted() {
        this.$router.replace(this.$route.path)
        if(storage.get(keys.check) && storage.get(keys.count) <= 1){
            this.naverLogin()
        }
    },
    methods: {
        ...mapActions('member', ['GET_MEMBER_INFO', 'LOGOUT', 'SET_SHOW_PAYMENT',]),
        ...mapActions('modal', ['SHOW_ALERT', 'SHOW_FIND_ID', 'SHOW_REGULAR_MEMBER_WELCOME', 'SHOW_FIND_ID_SNS']),
        ...mapMutations('member', ['SET_SHOW_PAYMENT']),

        payPopup(){
            console.log('this.$store', this.$store)
        },
        goMypage(){
            this.$router.push('MyPage/MemberInfo')
        },              
        onClickLogin() {
            if (this.id === '') {
                this.SHOW_ALERT({ content: '아이디를 입력하세요.' })
                return
            } else if (this.id.length < 4) {
                this.SHOW_ALERT({
                    content:
                        '가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.',
                })
                return
            }

            if (this.password === '') {
                this.SHOW_ALERT({ content: '비밀번호를 입력하세요.' })
                return
            }

            this.login()
        },

        onClickFindIdPwd() {
            // this.HIDE_MODAL()
            this.findId()
        },

        onClickLogout() {
            this.logout()
        },

        async login() {
            const params = {
                id: this.id,
                password: this.$CryptoJS.SHA1(this.password).toString(),
                // password: this.password,
            }

            console.log('login Params=', params)
            await api.login(params)
            .then((res)=>{
                console.log('login res', res)

                if (!res.success) {
                    this.SHOW_ALERT({ content: '로그인 실패' })
                }

                // modal 가입완료 표시
                else if (res.success) {
                    // this.SHOW_ALERT({ content: '로그인 성공' })

                    // 로그인 정보(로그인 유지 판단용) 스토리지에 저장
                    storage.set(keys.loginInfo, res.data)

                    // member store 갱신
                    this.getMemberInfo(this.id)

                    // id 저장
                    if (this.checkedIdSave) {
                        storage.set(keys.id, this.id)
                    } else {
                        storage.remove(keys.id)
                    }
                }
            })
            .catch((err)=>{ // 220816 meej main error page
                alert(err)
            })
        },

        getMemberInfo(id) {
            const params = {
                id,
                // id: 'testid4', // TEST
            }
            console.log('info params', id)
            this.GET_MEMBER_INFO(params)
        },

        logout() {
            /**
             * plugin/method.js 에 공통 method 사용
             */
            this.$method.logout(this.logoutCallback)
        },

        findId() {
            this.SHOW_FIND_ID()
        },

        logoutCallback() {
            this.resetInput()
        },

        resetInput() {
            console.log('resetInput')
            /* 20210518 shb 추가
            로그아웃 후 저장한 id가 있다면 input 창에 저장 id 넣어준다. */
            storage.remove(keys.check)
            storage.remove(keys.count)
            const savedId = storage.get(keys.id)
            if (savedId) {
                this.id = savedId
                this.checkedIdSave = true
            } else {
                this.id = ''
            }
            this.password = ''
        },
        // SNS
        kakaoLogin() {
            
            window.Kakao.Auth.login({
                scope: "profile_nickname, account_email",
                success: this.getKakaoAccount,
            });
        },
        getKakaoAccount() {
            window.Kakao.API.request({
                url: "/v2/user/me",
                success: (res) => {
                    const KakaoAccount = res.kakao_account;
                    const name = KakaoAccount.profile.nickname;
                    this.name = name;
                    const email = KakaoAccount.email;          
                    this.email = email; 
                    
                    console.log("res", JSON.stringify(res, null, 2));
                    this.SNSsignUpRegular()
                },
                fail: (error) => {
                    console.log(error);
                },
            });
        },

        async naverLogin() {
            this.naverLoginInstance = new window.naver.LoginWithNaverId({
                clientId: "YnPgNXS7jb27xHWJwWWM",
                callbackUrl: "https://new.infostock.co.kr/" || "https://www.infostock.co.kr/",
                isPopup: false,
                loginButton: {
                color: "green",
                type: 3,
                height: 28,
                },
            });

            await this.naverLoginInstance.init();

            storage.set(keys.count, keys.count +1)
            storage.set(keys.check, true)
            this.getUserInfo()
        },
        getUserInfo() {
            this.naverLoginInstance.getLoginStatus(status => {
                if (status) {
                    const email = this.naverLoginInstance.user.email;
                    this.email = email;
                    const name = this.naverLoginInstance.user.name;
                    this.name = name;
                    console.log("this.naverLoginInstance : ", JSON.stringify(this.naverLoginInstance,  null, 2));
                    this.SNSsignUpRegular()
                } else {
                    console.log("AccessToken이 올바르지 않습니다.");
                }
            });
        },
        async checkIdDup() {

            if(this.isIdDupCheck !== false){
                const params = {
                    id: this.email,
                }
                
                const res = await api.checkIdDup(params)
                console.log("id ", this.email)
                console.log("res : ", res)
                if (!res) {
                    this.SHOW_ALERT({
                        content: '서버에서 데이터를 불러올 수 없습니다.',
                    })
                    return
                }
                this.isIdDupCheck = res.success
            } 
        },
        async SNSsignUpRegular() {
            await this.checkIdDup();
            console.log(" isUD : ", this.isIdDupCheck)
            if(this.isIdDupCheck === true){
                this.SHOW_FIND_ID_SNS()
                // await api.SNSsignUp(params)
                // this.SET_SHOW_PAYMENT(true)
                // await this.SNSlogin()
                // this.SHOW_REGULAR_MEMBER_WELCOME()
                // this.$router.push({ path: '/MyPage/MemberInfo' })
            }
            else if(this.isIdDupCheck === false){
            // modal 가입완료 표시
                // 로그인 아이디 store에 저장
                // 가입완료 후 성공 시, 바로 로그인 처리
                await this.SNSlogin()
            }
        },

        async getNext(){
            const params = {
                id: this.email,
                // password: this.$CryptoJS.SHA1(this.password).toString(),
                name: this.name,
                type: 'ASSOCIATE',
                phone: this.memberPhoneNo
            }
            this.params = params
            console.log("멤버 가입 파라미터 : ", this.params)
            await api.SNSsignUp(this.params)
            this.SET_SHOW_PAYMENT(true)
            await this.SNSlogin()
            this.SHOW_REGULAR_MEMBER_WELCOME()
            this.$router.push({ path: '/MyPage/MemberInfo' })
        },
        
        async SNSlogin() {
            const params = {
                id: this.email,
            }
            console.log('login Params=', params)
            await api.SNSlogin(params)
                .then((res) => {
                    console.log('login res', res)

                    if (!res.success) {
                        this.SHOW_ALERT({ content: '로그인 실패' })
                    }

                    // modal 가입완료 표시
                    else if (res.success) {
                        // this.SHOW_ALERT({ content: '로그인 성공' })
                        
                        // 로그인 정보(로그인 유지 판단용) 스토리지에 저장
                        storage.set(keys.loginInfo, res.data)

                        // member store 갱신
                        this.getMemberInfo(this.eamil)

                        // id 저장
                        if (this.checkedIdSave) {
                            storage.set(keys.id, this.eamil)
                        } else {
                            storage.remove(keys.id)
                        }
                    }
                })
                .catch((err) => { // 220816 meej main error page
                    alert(err)
                })
        },

    },
}
